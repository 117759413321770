import React from "react"

import { Panel } from "./Panel"
import { Link } from "gatsby"

export function PageHeader() {
  return (
    <Panel className="panel--header">
      <div className="header">
        <div className="header__inner">
          <div className="header__logo">
            <div className="logo">Revli</div>
          </div>
          <div className="header__nav">
            <nav className="nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </Panel>
  )
}
