import React from "react"
import Helmet from "react-helmet"

export function Meta({ title }) {
  return (
    <Helmet>
      <title>
        {title
          ? `${title} | Revli | Web Design and Development Consultancy`
          : "Revli | Web Design and Development Consultancy | Glasgow, Uk"}
      </title>
      <meta
        name="description"
        content="Revli is a consultancy agency specialising in web design and development based in Glasgow, UK."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta
        name="og:image"
        content="https://arminsolecki.com/images/arminsolecki.jpg"
      />
      <meta
        name="og:description"
        content="Revli is a consultancy agency specialising in web design and development based in Glasgow, UK."
      />
      <meta name="og:url" content="https://revli.co.uk/" />
      <meta name="og:site_name" content="Revli" />

      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content="Revli | Web Design and Development Consultancy"
      />
      <meta
        name="twitter:description"
        content="Revli is a consultancy agency specialising in web design and development based in Glasgow, UK."
      />
      <meta
        name="twitter:image:src"
        content="https://arminsolecki.com/images/arminsolecki.jpg"
      />
      <meta name="twitter:url" content="https://revli.co.uk/" />
    </Helmet>
  )
}
